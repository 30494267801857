@use './variable.scss' as *;
@use './font.scss' as *;
@use './live-chat.scss' as *;
@use './override-header.scss' as *;
@use './antd-adjust/drawer-adjust.scss' as *;
@use './antd-adjust/message-adjust.scss' as *;
@use './antd-adjust/modal-adjust.scss' as *;
@use './antd-adjust/select-adjust.scss' as *;
@use './antd-adjust/antd-select-arrow.scss' as *;
@use './base.scss' as *;

// import tailwind css
@tailwind base;
@tailwind components;
@tailwind utilities;

:where(.tailwind) {
  margin: 0;
  padding: 0;
  color: var(--textNormalColor);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-size: $font-size-base;
  font-variant: tabular-nums;
  font-feature-settings: 'tnum', 'tnum';
  .ant-btn-primary {
    color: #fff;
    background: var(--primaryColor);
  }
  .ant-rate,
  .ant-rate-star {
    display: inline-block;
  }
  .ant-select,
  .ant-rate-star {
    position: relative;
  }
  .ant-rate-star-first {
    position: absolute;
  }
  .ant-select-selection-wrap,
  .ant-select-selection-search-input {
    width: 100%;
  }

  & a:focus {
    outline: -webkit-focus-ring-color auto 5px;
  }

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-family:
      var(--font-montserrat), Arial, Helvetica, sans-serif !important;
    color: rgba(0, 0, 0, 0.85);
  }

  & p,
  & ul,
  & ol {
    font-family:
      var(--font-montserrat), Arial, Helvetica, sans-serif !important;
  }

  // overwrite normalize.css
  & p {
    line-height: 150%;
    margin-bottom: 0;
  }
}

a:hover {
  color: var(--colorPrimaryHover);
}

*:focus-visible {
  outline: 3px solid var(--textNormalColor);
  outline-offset: 1px;
}

body {
  background-color: #fff;
}

@layer components {
  // Naming matches Figma Typography styles
  .body {
    @apply text-base font-light;
  }

  .body-sm {
    @apply text-sm font-light;
  }

  .body-lg {
    @apply text-xl font-normal;
  }

  .h2 {
    @apply text-35xl md:text-4xl md:leading-11 font-bold;
  }

  .h3 {
    @apply text-25xl md:text-275xl font-bold;
    @apply text-25xl md:text-275xl md:leading-11 font-bold;
  }

  .h4 {
    @apply text-xl leading-64 md:text-2xl font-bold;
  }

  .h5 {
    @apply text-lg leading-6 md:text-xl md:leading-65 font-bold;
  }

  .h6 {
    @apply text-base font-bold;
  }

  .footer-heading {
    @apply text-black font-black text-lg whitespace-nowrap;
    line-height: 1.5;
    margin-bottom: 15px;
    font-family:
      var(--font-montserrat), 'Arial Black', Arial, Helvetica, sans-serif;
  }
}

hr {
  border-color: #e5e7eb;
}

.border,
.border-x,
.border-y,
.border-1,
.border-2,
.border-t,
.border-b,
.border-l,
.border-r,
.border-t-2,
.border-b-2,
.border-r-2 {
  border-style: solid;
  border-color: #e5e7eb;
}

.border-textNormalColor {
  border-color: #2d2d2d !important;
}
.border-\[\#333\] {
  border-color: #333 !important;
}

// float related
.clear-fix {
  &::after {
    display: block;
    clear: both;
    content: '';
  }
}

// text truncation
.one-line-text {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.two-line-text {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.three-line-text {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.four-line-text {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

// position tool
.absolute-center {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

//screen-reader-only
.screen-reader-only {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  color: #000 !important;
  background-color: #fff !important;
  border: 0 !important;
  clip: rect(0, 0, 0, 0) !important;
}

.border {
  border-style: solid;
}

// ECMD-13716
.configList {
  display: none;
}

.pencil-banner a:hover {
  color: white;
  text-decoration: underline;
}

#giftCertContainer .submit.styleBtn {
  color: #fff;
}
#gc_mainNav:empty {
  display: none;
}
