body {
  .ant-select-item-option-disabled {
    color: var(--borderNormalColor);
  }
}
// fix the antd selection style
::selection {
  background: #1890ff;
  color: white;
}
