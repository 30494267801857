.ant-select .ant-select-arrow {
  width: 20px;
  height: 20px;

  .anticon-caret-down {
    display: none;
  }

  &:after {
    position: absolute;
    top: -4px;
    display: inline-block;
    content: '';
    width: 20px;
    height: 20px;
    background: url('/assets/images/pdp/qty-chevron-down.svg') no-repeat;
  }

  &.ant-select-focused.ant-select-open:after {
    transform: rotate(180deg);
    top: -4px;
  }
}
