h2[embeddedService-chatHeader_chatHeader] {
  color: #fff;
}

body {
  .interstitialSidebar {
    box-sizing: content-box;

    #callback {
      .header {
        h3 {
          font-weight: bold;
        }
        h4 {
          font-weight: bold;
        }
      }
    }

    h4 {
      text-align: start;
      padding: 0 20px 20px 20px;
      font-size: x-large;
    }

    h5 {
      font-weight: bold;
      font-size: 12px;
    }

    button,
    input,
    optgroup,
    select,
    textarea {
      line-height: normal;
    }
  }

  .embeddedServiceSidebar,
  .interstitialSidebar {
    img,
    svg,
    video,
    canvas,
    audio,
    iframe,
    embed,
    object {
      vertical-align: baseline;
      display: inline;
    }
  }
}
