body .element-hidden {
  display: none;
}
body #pr-reviewdisplay {
  display: block;
}
.gc-overflow-hidden {
  overflow: hidden;
}
.basic-layout.old-header-overlay {
  position: relative;
  &:before {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: -16px;
    bottom: 0;
    background-color: #000;
    z-index: 11;
    opacity: 0.6;
  }
  .header-layout {
    position: relative;
    z-index: 12;
    background-color: white;
  }
  #PDPStickyNav,
  .mobile-fix-container {
    z-index: 10;
  }
}
#preHeaderRollWrapper.pre-header-roll-wrapper {
  position: relative;
  z-index: 1000;
  background: white;
}
body:has(.old-header-overlay),
body.overlay-and-overflow-hidden {
  .plp-compare-modal.ant-drawer-open {
    z-index: 10;
  }
}
