// fix antd message icon display issue
.ant-message .ant-message-notice .anticon {
  top: -3px;
}

.ant-message {
  z-index: 1111;
}

.checkout-error-message,
.checkout-warning-message,
.checkout-success-message {
  .ant-message-notice-content {
    width: 100%;
    padding: 0 !important;
    box-shadow: none;
  }
  .ant-message-custom-content {
    display: flex;
    width: 100%;
    align-items: stretch;
  }
  .alert-message-icon + span {
    display: flex;
    flex: 1 1 auto;
    flex-basis: 100%;
    align-items: flex-start;
    border: 1px solid #bbb;
    border-left-width: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background: white;
  }
  .message-content-wrap {
    display: flex;
    align-items: stretch;
    flex-basis: 100%;
    padding: 8px 12px;
  }
}

.cs-tel,
.couponChat {
  text-decoration: underline;
  cursor: pointer;
  font-family: var(--font-montserrat), Arial, Helvetica, sans-serif;
}
