.tailwind {
  .ant-modal {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    height: fit-content;
    max-height: 100%;
    margin: auto;
    padding-bottom: 0;
  }
  .ant-modal-close {
    top: 5px;
    right: 5px;
    &:hover {
      color: #ffffff;
    }
  }
  .ant-modal-close-icon {
    vertical-align: initial;
    display: inline-block;
    width: 26px;
    height: 27px;
    background: url(/assets/images/common/wishlist-sprite.png)
      no-repeat -112px -50px;
    svg {
      display: none;
    }
  }
  .ant-modal-body {
    position: relative;
    margin-top: 32px;
    padding: 0;
  }
  .ant-modal-header {
    padding: 24px 8px 8px;
    background-color: var(--textNormalColor);
  }
  .ant-modal-footer {
    padding: 24px 0;
  }
  .ant-modal-title *,
  .ant-modal-close {
    color: #ffffff;
  }
  .ant-modal .ant-modal-content {
    padding: 0;
  }
  .ant-modal .ant-modal-close:hover {
    background-color: transparent;
  }
  .ant-modal * {
    font-family:
      -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
      Arial, sans-serif;
  }
}

@media (max-width: 850px) {
  .tailwind .ant-modal {
    height: 100%;
    min-height: 100%;
  }
  .tailwind .ant-modal-body {
    padding: 0 12px;
  }
  .tailwind .ant-modal-content {
    height: 100%;
    min-height: 100%;
    overflow-y: auto;
  }
}

body {
  .ant-modal-wrap {
    z-index: 1001;
  }
}
