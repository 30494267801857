:root,
::before,
::after {
  /******************* common ********************/
  /* red */
  --primaryColor: #e7131a;
  --ghostColor: var(--primaryDarkColor);
  --errorColor: #d30000;
  /* yellow */
  --warningColor: #eb703b;
  /* black */
  --textNormalColor: #2d2d2d;
  --textLightColor: #555555;
  --textDarkColor: #2d2d2d;
  /* gray */
  --summaryBackground: #f9f9f9;
  --borderLessLightColor: #eee;
  --borderLightColor1: #dbdcde;
  --borderLightColor2: #ddd;
  --borderLightColor3: #dadedd;
  --borderNormalColor: #bbbbbb;
  --borderDarkColor: #707070;
  --borderCheckboxColor: #d9d9d9;
  /* green */
  --greenNormal: #0ea200;
  /**
     *
     *
     *
     *
     */
  /******************* private ********************/
  /* red */
  --primaryLessDarkColor: #cd2418;
  --colorPrimaryHover: #f53b3b;
  --primaryDarkColor: #b30f14;
  /* green */
  --greenDark: #058000;
  --greenLessDark: #1d862c;
  /* gray */
  --borderMoreLightColor: #e7e7e7;
  --font-montserrat: 'Montserrat', Arial, Helvetica, sans-serif;
}
