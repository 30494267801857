.gc-font-light {
  font-weight: 400;
}
.gc-font-regular {
  font-weight: 600;
}
.gc-font-bold {
  font-weight: 700;
}
.gc-font-black {
  font-weight: 900;
}
