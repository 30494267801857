// -------- site --------
$store-name: 'gc';
$static-url: 'https://static.guitarcenter.com';
$static-landing-url: '#{$static-url}/static/gc/lpages';
$static-fonts-url: '#{$static-url}/static/fonts';

// -------- Colors -----------
$blue-base: #1890ff;
$green-base: #52c41a;
$gray: #ddd;
$dark-gray: #888;
$gc-primary-red: #e7131a;
$gc-primary-red-dark: #b30f14;
$font-black: #2d2d2d;
$mf-yellow: #fcb715;
$padding-md: 16px;
$gc-btn-default-bg: #dadbdd;

// -------- border --------
$border-width-lg: 2px;
$border-radius-lg: 4px;

$font-size-base: 14px;
